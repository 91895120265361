import { Link } from '@remix-run/react'
import { useIntersectionObserver } from 'usehooks-ts'
import data1 from '~/assets/images/site/accueil/data1.png'
import depotsauvage1 from '~/assets/images/site/accueil/depotsauvage1.jpg'
import goupil from '~/assets/images/site/accueil/goupil.jpg'
import ville2 from '~/assets/images/site/ville2.svg'
import logo from '~/assets/logo/zamas_logo.svg'
import { Button } from '~/components/ui/Button.tsx'
import { Container } from './components/container.tsx'
import CookieConsent from './components/cookieConsent.tsx'
import { Image } from './components/image.tsx'
import { ListItem } from './components/list-item.tsx'
import { List } from './components/list.tsx'
import { Section } from './components/section.tsx'
import { Text } from './components/text.tsx'

export default function Home() {
	const { isIntersecting: isIntersecting1, ref: ref1 } = useIntersectionObserver({ freezeOnceVisible: true })
	const { isIntersecting: isIntersecting2, ref: ref2 } = useIntersectionObserver({ freezeOnceVisible: true, threshold: 0.4 })
	const { isIntersecting: isIntersecting3, ref: ref3 } = useIntersectionObserver({ freezeOnceVisible: true, threshold: 0.3 })
	const { isIntersecting: isIntersecting4, ref: ref4 } = useIntersectionObserver({ freezeOnceVisible: true, threshold: 0.3 })
	const { isIntersecting: isIntersecting5, ref: ref5 } = useIntersectionObserver({ freezeOnceVisible: true, threshold: 0.2 })

	const sectionMargin = 'mt-16'

	return (
		<div className="max-w-screen mt-8 flex h-full w-full items-center justify-center overflow-hidden">
			<CookieConsent />
			<Container className="">
				<Section direction="row" ref={ref1} className="">
					<div className={`${isIntersecting1 ? '' : '-translate-y-full opacity-0'} flex flex-col items-center text-center transition duration-1000 ease-in md:items-start md:text-left`}>
						<img src={logo} alt="" className="w-full text-xl md:w-[24ch] xl:text-2xl 3xl:text-3xl 4xl:text-4xl" />
						<Text variant="Text1" tagType="h1" className={`${isIntersecting1 ? '' : 'translate-y-1/2 opacity-0'} linear mt-8 font-bold transition delay-900 duration-700 md:max-w-[38ch] 3xl:mt-12 4xl:mt-16`}>
							<span>Un assistant </span>
							<span className="text-primary">intelligent </span>
							<span className="">à la </span>
							<span className="text-primary">planification </span>
							<span className="">et au </span>
							<span className="text-primary">suivi opérationnel </span>
							<span className="">des services techniques communaux.</span>
						</Text>
						<Text variant="Text2" tagType="p" className={`${isIntersecting1 ? '' : 'translate-y-1/2 opacity-0'} mt-4 tracking-tighter text-muted-600 transition delay-1400 duration-700 3xl:mt-6 3xl:tracking-normal 4xl:mt-8`}>
							La solution pour une gestion optimisée de votre service
						</Text>
					</div>
					<div className={`${isIntersecting1 ? '' : 'translate-x-full translate-y-full opacity-0'} min-w-max translate-y-8 self-center transition-all duration-1000 ease-in`}>
						<Image src={data1} alt="nettoyage karsher ville" className="" />
					</div>
				</Section>

				<Section direction="row" ref={ref2} className={`${sectionMargin}`}>
					<div className={`${isIntersecting2 ? '' : 'translate-x-full opacity-0'} flex flex-col items-center text-justify transition duration-700`}>
						<Text variant="Text2" tagType="p" className="border-t-8 border-muted-300 pt-8 font-bold">
							<span>Simplifiez et améliorez la gestion de vos services techniques communaux grâce à notre </span>
							<span className="text-primary">logiciel spécialisé </span>
							<span>dans la propreté urbaine.</span>
						</Text>
						<Text variant="Text3" tagType="p" className={` font-normal text-muted-600 ${isIntersecting2 ? '' : 'translate-x-full opacity-0'} mt-4 transition delay-700 duration-700 ease-linear`}>
							Logiciel métier développé depuis deux ans par nos ingénieurs dans le but d'accompagner les communes dans leurs transformations numériques
						</Text>
					</div>

					<div className={`${isIntersecting2 ? '' : '-translate-x-full opacity-0'} min-w-max transition duration-700 md:order-first`}>
						<Image src={depotsauvage1} alt="dépôt sauvage propreté urbaine" />
					</div>
				</Section>

				<Section direction="row" ref={ref3} className={`${sectionMargin}`}>
					<div className={`${isIntersecting3 ? '' : '-translate-x-full opacity-0'} flex flex-col items-center text-justify transition duration-700`}>
						<Text variant="Text2" tagType="p" className="border-t-8 border-muted-300 pt-8 font-bold">
							<span>Centraliser vos données pour </span>
							<span className="text-primary">gagner du temps et optimiser </span>
							<span>le fonctionnement de votre service grâce à ZAMAS:</span>
						</Text>
						<Text variant="Text3" tagType="div" className="mt-4 w-full font-normal text-muted-600">
							<List>
								<ListItem className={`${isIntersecting3 ? '' : '-translate-x-full opacity-0'}  transition delay-600 duration-700 ease-out`}>Planifier les interventions</ListItem>
								<ListItem className={`${isIntersecting3 ? '' : '-translate-x-full opacity-0'}  transition delay-700 duration-700 ease-out`}>Créer les emplois du temps de vos agents</ListItem>
								<ListItem className={`${isIntersecting3 ? '' : '-translate-x-full opacity-0'}  transition delay-800 duration-700 ease-out`}>Gérer les véhicules</ListItem>
								<ListItem className={`${isIntersecting3 ? '' : '-translate-x-full opacity-0'}  transition delay-900 duration-700 ease-out`}>Cartographier les équipements</ListItem>
							</List>
						</Text>

						<Text variant="Text4" tagType="p" className={`${isIntersecting3 ? '' : 'translate-y-full opacity-0'} mt-16 w-full font-semibold transition delay-1400 duration-700 ease-out`}>
							Toutes les données sont disponibles à la consultation et à l'export, dans une interface lisible et ergonomique
						</Text>
					</div>
					<div className={`${isIntersecting3 ? '' : 'translate-x-full opacity-0'} min-w-max transition duration-700 `}>
						<Image src={goupil} alt="dépôt sauvage propreté urbaine" className="" />
					</div>
				</Section>

				<Section direction="col" ref={ref4} className={`${sectionMargin} text-center`}>
					<Text variant="Text2" tagType="div" className="flex flex-col gap-2 font-bold">
						<p className={`${isIntersecting4 ? '' : 'translate-y-96  opacity-0'}  text-primary transition duration-500`}>Simple à prendre en main,</p>
						<p className={`${isIntersecting4 ? '' : 'translate-y-96 opacity-0'}  transition delay-500 duration-500`}>Ergnomique,</p>
						<p className={`${isIntersecting4 ? '' : 'translate-y-96  opacity-0'}  text-primary transition delay-800 duration-500`}>Sécurisé,</p>
						<p className={`${isIntersecting4 ? '' : 'translate-y-96  opacity-0'}  my-1 font-medium transition delay-800 duration-500`}>le logiciel ZAMAS propose des outils organisationnels, cartographiques et opérationnels pour restituer vos données.</p>
					</Text>
					<Text variant="Text3" tagType="p" className={`${isIntersecting4 ? '' : 'translate-y-96  opacity-0'}  font-normal text-muted-600 transition delay-1000 duration-300`}>
						<span>Conscients des besoins spécifiques de chaque commune, notre </span>
						<span className="font-semibold">équipe d'experts </span>
						<span>en gestion de données et en propreté urbaine vous accompagne dans l'intégration et la prise en main du logiciel.</span>
					</Text>
					<div className="relative mt-8 flex w-full justify-center">
						<img src={ville2} alt="zamas picto ville" className="scale-90" />
						<div className={`${isIntersecting4 ? 'translate-x-full' : ''} duration-[1000ms] absolute inset-0 h-full w-full bg-background transition delay-1000`}></div>
					</div>
				</Section>

				<Section direction="col" ref={ref5} className={`${sectionMargin}`}>
					<Text variant="Text2" tagType="p" className={`${isIntersecting5 ? '' : 'translate-y-96 opacity-0'} mb-4  text-center font-bold transition duration-500`}>
						Nous sommes là pour vous aider à tirer le meilleur parti de notre solution.
					</Text>
					<Text variant="Text3" tagType="p" className={`${isIntersecting5 ? '' : 'translate-x-full opacity-0'} text-justify font-normal text-muted-600 transition delay-500 duration-500`}>
						Partenaire de confiance pour les communes qui souhaitent moderniser et améliorer la gestion de leurs services. Seule solution proposant à la fois un logiciel métier complet et un accompagnement personnalisé, nous travaillons main dans la main avec les services techniques communaux pour proposer des solutions sur mesure, adaptées à leurs besoins et à leur budget.
					</Text>
					<Text variant="Text3" tagType="p" className={`${isIntersecting5 ? '' : '-translate-x-full opacity-0'} text-justify font-normal text-muted-600 transition delay-500 duration-500`}>
						Zamas centralise la gestion de vos données pour que vous puissiez vous concentrer sur votre mission première : assurer la propreté et le bien-être de votre commune. Faites confiance à notre expertise et rejoignez les nombreuses communes satisfaites par notre solution.
					</Text>
					<Button asChild variant="accent-magenta" size="wide" className="my-12 self-center">
						<Link to="/contact-us">N'attendez plus pour essayer !</Link>
					</Button>
				</Section>
			</Container>
		</div>
	)
}
